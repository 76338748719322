// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `a {
  color: cornflowerblue;
  cursor: pointer;
}
a:hover {
  text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./src/app/complainr-entry/complainr-entry.component.less"],"names":[],"mappings":"AAAA;EACI,qBAAA;EACA,eAAA;AACJ;AAEA;EACI,0BAAA;AAAJ","sourcesContent":["a {\n    color: cornflowerblue;\n    cursor: pointer;\n}\n\na:hover {\n    text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
