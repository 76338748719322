// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.social-bar-top,
.social-bar-top a {
  font-size: 40px;
  letter-spacing: 4px;
  color: #FF6043;
}
.container .row .col-10 {
  text-align: left;
  font-size: 40px;
}
.container .row a {
  font-variant: small-caps;
  color: #FF6043;
  margin-right: 30px;
}
.header-block {
  background-color: #FF6043;
  margin-bottom: 50px;
  height: 200px;
}
.header-block h1 {
  padding-top: 50px;
  color: white;
  font-size: 60px;
}
.blink {
  animation: blink 2s steps(5, start);
  -webkit-animation: blink 1s steps(5, start);
  animation-iteration-count: 5;
}
@keyframes blink {
  to {
    visibility: hidden;
  }
}
`, "",{"version":3,"sources":["webpack://./src/app/complainr-logo/complainr-logo.component.less"],"names":[],"mappings":"AAEA;;EACE,eAAA;EACA,mBAAA;EACA,cAAA;AAAF;AAIA;EACE,gBAAA;EACA,eAAA;AAFF;AAKA;EACE,wBAAA;EACA,cAAA;EACA,kBAAA;AAHF;AAMA;EACE,yBAAA;EACA,mBAAA;EACA,aAAA;AAJF;AACA;EAMI,iBAAA;EACA,YAAA;EACA,eAAA;AAJJ;AAQA;EACE,mCAAA;EACA,2CAAA;EACA,4BAAA;AANF;AASA;EACE;IACE,kBAAA;EAPF;AACF","sourcesContent":["@complainrOrange: #FF6043;\n\n.social-bar-top, .social-bar-top a {\n  font-size: 40px;\n  letter-spacing: 4px;\n  color: @complainrOrange;\n}\n\n\n.container .row .col-10 {\n  text-align: left;\n  font-size: 40px;\n}\n\n.container .row a {\n  font-variant: small-caps;\n  color: @complainrOrange;\n  margin-right: 30px;\n}\n\n.header-block {\n  background-color: #FF6043;\n  margin-bottom: 50px;\n  height: 200px;\n\n  h1 {\n    padding-top: 50px;\n    color: white;\n    font-size: 60px;\n  }\n}\n\n.blink {\n  animation: blink 2s steps(5, start);\n  -webkit-animation: blink 1s steps(5, start);\n  animation-iteration-count: 5;\n}\n\n@keyframes blink {\n  to {\n    visibility: hidden;\n  }\n}\n\n@-webkit-keyframes blink {\n  to {\n    visibility: hidden;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
