import { Component, OnInit, Input } from '@angular/core';
import { Entry } from '../models/entry';
import { Router } from '@angular/router';

@Component({
  selector: 'complainr-entry',
  templateUrl: './complainr-entry.component.html',
  styleUrls: ['./complainr-entry.component.less']
})
export class ComplainrEntryComponent implements OnInit {

  @Input()
  public entry: Entry = new Entry();

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  public navigate(): void {
    this.router.navigate(["en", this.entry.slug]);
  }
}
