import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ComplainrLogoComponent } from './complainr-logo/complainr-logo.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  public desktop: boolean = true;

  constructor(
    private device: DeviceDetectorService) {
    this.desktop = this.device.isDesktop();

  }
}
