import { Component, OnInit } from '@angular/core';
import { SocialSharerInitService } from '../social-sharer-init.service';

@Component({
  selector: 'complaint-queued-for-moderation',
  templateUrl: './complaint-queued-for-moderation.component.html',
  styleUrls: ['./complaint-queued-for-moderation.component.less']
})
export class ComplaintQueuedForModerationComponent implements OnInit {

  constructor(private detectchanges: SocialSharerInitService) { }

  ngOnInit(): void {
  }

}
