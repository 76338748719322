import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ComplainrAddFormComponent } from './complainr-add-form/complainr-add-form.component';
import { ComplainrNotFoundComponent } from './complainr-not-found/complainr-not-found.component';
import { ComplainrSingleOverviewComponent } from './complainr-single-overview/complainr-single-overview.component';
import { ComplaintQueuedForModerationComponent } from './complaint-queued-for-moderation/complaint-queued-for-moderation.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { OverviewComponent } from './overview/overview.component';
import { PrivacydisclaimerComponent } from './privacydisclaimer/privacydisclaimer.component';

const routes: Routes =  [
  { path: '', component: OverviewComponent, pathMatch: 'full' },
  { path: 'add/:suggestion', component: ComplainrAddFormComponent, pathMatch: 'full' },
  { path: 'moderator/:suggestion', component: ComplaintQueuedForModerationComponent, pathMatch: 'full' },
  { path: 'en/:slug', component: ComplainrSingleOverviewComponent, pathMatch: 'full' },
  { path: 'facebook/en/:slug', component: ComplainrSingleOverviewComponent, pathMatch: 'full' },
  { path: 'notfound', component: ComplainrNotFoundComponent, pathMatch: 'full' },
  { path: 'privacy', component: PrivacydisclaimerComponent, pathMatch: 'full' },
  { path: 'contact-us', component: ContactUsComponent, pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
