import { Injectable, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SocialSharerInitService implements OnInit {
  constructor(private router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.reInitSocialShare();
      }
    });
  }

  public reInitSocialShare() {
    console.info("re-init sharethis");
    var sharethis: any = window["__sharethis__"];
    const elements = document.getElementsByClassName("st-sticky-share-buttons");
    while (elements.length > 0)
      elements[0].remove();
    sharethis.load("sticky-share-buttons", {
      "alignment": "left",
      "color": "social",
      "enabled": true,
      "hide_desktop": false,
      "labels": "cta",
      "language": "en",
      "min_count": 10,
      "mobile_breakpoint": 1024,
      "networks": ["facebook", "twitter", "email", "sms", "messenger", "sharethis"],
      "num_networks": 6,
      "padding": 12,
      "radius": 4,
      "show_mobile": true,
      "show_toggle": true,
      "show_total": true,
      "size": 48,
      "top": 160,
      "use_native_counts": true,
      "url": window.location.href
    });
  }

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.reInitSocialShare();
      }
    });
  }
}
