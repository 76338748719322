import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Entry } from '../models/entry';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SocialSharerInitService } from '../social-sharer-init.service';

@Component({
  selector: 'overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.less']
})
export class OverviewComponent implements OnInit {
  public daily: Array<Entry> = [];
  public weekly: Array<Entry> = [];
  public allTime: Array<Entry> = [];
  public latest: Array<Entry> = [];

  public foundEntries: Array<Entry> = [];

  public mode: string = "";

  private timer: any;
  private delaySearch: boolean = true;

  public desktop: boolean = true; // default to the desktop version
  public suggestion: string = "";

  @Output()
  public EntriesFoundEvent: EventEmitter<Array<Entry>> = new EventEmitter<Array<Entry>>();


  constructor(private http: HttpClient,
    private router: Router,
    private title: Title,
    private device: DeviceDetectorService,
    private detectchanges: SocialSharerInitService) {
    title.setTitle("Complainr - Things the internet is irritated about");
    this.desktop = this.device.isDesktop();
  }

  sumbitGrievance(): void {
    if (this.suggestion.length > 2) {
      this.http.post<boolean>(environment.root + "/api/v1/SuggestionExists", { suggestion: this.suggestion })
        .subscribe(exists => {
          if (exists) {
            this.http.post<string>(environment.root + "/api/v1/GetSlugByLabel", { email: "system@localhost.com", suggestion: this.suggestion })
              .subscribe(x => {
                if (x === null) {
                  this.router.navigate(['moderator', this.suggestion]); // already added, but in moderation queue
                } else {
                  this.router.navigate(['en', x]); // already added and allowed
                }
              });
          } else {
            this.router.navigate(['add', this.suggestion]); // go to the submission screen
          }
        });
    }
  }

  updateSuggestions(e: any): void {
    this.suggestion = e.target.value;

    if (this.delaySearch) {

      // if there is already a timer running... then stop it
      if (this.timer) {
        clearTimeout(this.timer);
      }

      // trigger the search action after 400 millis
      this.timer = setTimeout(async () => {
        await this.fetchSuggestions(e.target.value);
      }, 400);
    }
  }

  async fetchSuggestions(suggestions: string): Promise<Array<Entry>> {
    if (typeof (suggestions) === undefined)
      return [];

    if (suggestions.trim() !== "" && suggestions.length > 3) {
      await this.http.post<Array<Entry>>(environment.root + "/api/v1/ComplainByPart", { suggestion: suggestions }).subscribe(x => {
        this.EntriesFoundEvent.emit(x);
      });
    } else {
      this.EntriesFoundEvent.emit([]);
    }
    return [];
  }

  ngOnInit(): void {

    this.http.get<Array<Entry>>(environment.root + "/api/v1/ComplaintsToday")
      .subscribe(x => this.daily = x);

    this.http.get<Array<Entry>>(environment.root + "/api/v1/ComplaintsThisWeek")
      .subscribe(x => this.weekly = x);

    this.http.get<Array<Entry>>(environment.root + "/api/v1/ComplaintsAllTime")
      .subscribe(x => this.allTime = x);

    this.http.get<Array<Entry>>(environment.root + "/api/v1/ComplaintsLatest")
      .subscribe(x => this.latest = x);

    this.EntriesFoundEvent.subscribe(x => {
      this.foundEntries = x;
      console.log(this.foundEntries);
    });

    this.mode = "week";

    this.detectchanges.reInitSocialShare();
  }

  navigate(slug: string): void {
    this.router.navigate(['en', slug]);
  }
}
