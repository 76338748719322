import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SocialSharerInitService } from '../social-sharer-init.service';

@Component({
  selector: 'app-complainr-add-form',
  templateUrl: './complainr-add-form.component.html',
  styleUrls: ['./complainr-add-form.component.less']
})
export class ComplainrAddFormComponent implements OnInit {

  public grievance: string = "";
  public email: string = "";
  public desktop: boolean = false;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private device: DeviceDetectorService,
    private detectchanges: SocialSharerInitService) {
    this.desktop = this.device.isDesktop();
  }

  ngOnInit(): void {
    this.route.params.subscribe(x => {
      this.grievance = x['suggestion'];
    });

    if (this.grievance === "") {
      this.router.navigate(["/"]);
    }
  }

  submitEmail(): void {
    if (this.email === "") {
      return;
    }

    this.http.post(environment.root + "/api/v1/AddNewSuggestion", {
      email: this.email,
      suggestion: this.grievance
    }).subscribe(x => {
      this.router.navigate(["moderator", this.grievance]);

    });

  }

}
