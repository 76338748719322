// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.complainr-logo {
  margin-right: auto;
  margin-left: auto;
}
.btn-share {
  white-space: nowrap;
  margin-top: 32px;
  margin-left: -25px;
}
`, "",{"version":3,"sources":["webpack://./src/app/overview/overview.component.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,iBAAA;AACF;AAEA;EACE,mBAAA;EACA,gBAAA;EACA,kBAAA;AAAF","sourcesContent":[".complainr-logo {\n  margin-right: auto;\n  margin-left: auto;\n}\n\n.btn-share {\n  white-space: nowrap;\n  margin-top: 32px;\n  margin-left: -25px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
