import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Entry } from '../models/entry';
import { Title } from '@angular/platform-browser';
import { SocialSharerInitService } from '../social-sharer-init.service';

@Component({
  selector: 'complainr-single-overview',
  templateUrl: './complainr-single-overview.component.html',
  styleUrls: ['./complainr-single-overview.component.less']
})
export class ComplainrSingleOverviewComponent implements OnInit {

  public entry: Entry = new Entry();
  public personPeople: string = "";
  public complainNoted: boolean = false;

  constructor(private route: ActivatedRoute,
    private http: HttpClient,
    private title: Title,
    private router: Router,
    private detectchanges: SocialSharerInitService) {

  }

  ngOnInit(): void {
    this.route.params.subscribe(x => {
      this.http.post<Entry>(environment.root + "/api/v1/GetEntryBySlug", { suggestion: x['slug'] })
        .subscribe(response => {
          if (response === null) {
            this.router.navigate(['notfound']);
          }

          this.entry = response;

          if (this.entry.numberOfEntries > 1) {
            this.personPeople = "people";
          } else {
            this.personPeople = "person";
          }

          this.title.setTitle("Complainr - I complain about " + this.entry.label);

          this.detectchanges.reInitSocialShare();
        });
    });
  }

  public submit(): void {
    this.http.post(environment.root + "/api/v1/AddExistingSuggestion", { suggestion: this.entry.label })
      .subscribe(() => {
        this.complainNoted = true;
      });
  }
}
