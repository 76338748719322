import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'complainr-logo',
  templateUrl: './complainr-logo.component.html',
  styleUrls: ['./complainr-logo.component.less']
})
export class ComplainrLogoComponent implements OnInit {
  public desktop: boolean = true;

  constructor(private router: Router,
    private device: DeviceDetectorService,
    private http: HttpClient) {
    this.desktop = this.device.isDesktop();
  }

  ngOnInit(): void {
  }

  public home(): void {
    this.router.navigate(["/"]);
  }

  public gotoAndroidPage(): void {
    window.location.href = "https://play.google.com/store/apps/details?id=com.microformatica.complainrandroid";
  }

  public gotoTwitterPage(): void {
    window.location.href = "https://twitter.com/Complainr2";
  }

  public androidIconVisible(): boolean {
    const userAgent = navigator.userAgent || navigator.vendor;
    return /Android/.test(userAgent) === true;
  }

  public iosIconVisible(): boolean {
    const userAgent = navigator.userAgent || navigator.vendor;
    return (/iPad|iPhone|iPod/.test(userAgent)) === true;
  }

  public canAskPermission(): boolean {
    return localStorage.getItem("notificationPermission") === null;
  }


  public toUInt8(dataURI:string): ArrayBuffer {

    var BASE64_MARKER = ';base64,';
    var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
    var base64 = dataURI.substring(base64Index);
    var raw = window.atob(base64);
    var rawLength = raw.length;
    var array = new Uint8Array(new ArrayBuffer(rawLength));

    for (let i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }
    return array;
  }

  public askPermission(): void {


    Notification.requestPermission().then(x => {
      localStorage.setItem("notificationPermission", x);

      if (localStorage.getItem("notificationPermission") === "granted") {
        var n = new Notification("Complainr", {
          body: "Thank you!",
          icon: "https://complainr.net/assets/complainr.png",
          requireInteraction: false,
          tag: "thank-you"
        });
        n.addEventListener("error", (event: any) => { console.log(event); });

        console.log("Requesting subscription ...");

      }
    });
  }
}
