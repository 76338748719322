import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacydisclaimer',
  templateUrl: './privacydisclaimer.component.html',
  styleUrls: ['./privacydisclaimer.component.less']
})
export class PrivacydisclaimerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
