export const environment = {
  production: true,
  shopurl: 'https://shop.spreadshirt.com/complainr/all',
  root: "https://" + window.location.hostname + ":" + window.location.port,
  firebase: {
    apiKey: "AIzaSyDwSVUCZ0KkzHiF_77e_sxKtB-Z9i9glS0",
    authDomain: "complainr-afe25.firebaseapp.com",
    databaseURL: "https://complainr-afe25.firebaseio.com",
    projectId: "complainr-afe25",
    storageBucket: "complainr-afe25.appspot.com",
    messagingSenderId: "266081412028",
    appId: "1:266081412028:web:eac62ab0a66aa40596c1b9",
    measurementId: "G-MFJP8MXBY9"
  },
  vapid: {
    publicKey: "BB_BmPwzkj72vY4EXxqrdy2oc0fXKp2GDC9_f8qn5WfYcobJWFIwy3z3PFIBwkEiPtrjSKbwXWc62ooBUTGwqMg"
  }
};
