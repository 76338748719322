import { importProvidersFrom, NgModule, provideZoneChangeDetection } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { OverviewComponent } from './overview/overview.component';

import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { DisqusModule } from "ngx-disqus";

import { ComplainrLogoComponent } from './complainr-logo/complainr-logo.component';
import { ComplaintQueuedForModerationComponent } from './complaint-queued-for-moderation/complaint-queued-for-moderation.component';
import { ComplainrEntryComponent } from './complainr-entry/complainr-entry.component';
import { ComplainrSingleOverviewComponent } from './complainr-single-overview/complainr-single-overview.component';
import { ComplainrNotFoundComponent } from './complainr-not-found/complainr-not-found.component';
import { ComplainrAddFormComponent } from './complainr-add-form/complainr-add-form.component';
import { PrivacydisclaimerComponent } from './privacydisclaimer/privacydisclaimer.component';
import { SocialSharerInitService } from './social-sharer-init.service';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';

@NgModule({
  declarations: [
    AppComponent,
    OverviewComponent,
    ComplainrLogoComponent,
    ComplaintQueuedForModerationComponent,
    ComplainrEntryComponent,
    ComplainrSingleOverviewComponent,
    ComplainrNotFoundComponent,
    ComplainrAddFormComponent,
    PrivacydisclaimerComponent,
    ContactUsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    DisqusModule,
    FormsModule,
LoadingBarModule
  ],
  providers: [SocialSharerInitService, importProvidersFrom(LoadingBarHttpClientModule), importProvidersFrom(LoadingBarRouterModule) ],
  bootstrap: [AppComponent]
})
export class AppModule { }
