import { Component, OnInit } from '@angular/core';
import { SocialSharerInitService } from '../social-sharer-init.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-complainr-not-found',
  templateUrl: './complainr-not-found.component.html',
  styleUrls: ['./complainr-not-found.component.less']
})
export class ComplainrNotFoundComponent implements OnInit {

  constructor(private detectchanges: SocialSharerInitService,
    private route: ActivatedRoute) { }

  public NotFoundRoute: string = "";

  ngOnInit(): void {
    this.NotFoundRoute = this.route.toString();
  }

}
