import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.less']
})
export class ContactUsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }


  public message: string = "";
  public email: string = "";

  public submitMessage(): void {

  }
}
